import ApiEstudianteService from "@/modules/Shared/services/ApiEstudianteService";
import PacienteService from "../service/pacienteService"
import ApiColaboradorService from "@/modules/Shared/services/ApiColaboradorService";
import swal from "sweetalert";
import store from "@/store";

const PacienteSV = new PacienteService;
const ApiStudent = new ApiEstudianteService;
const ApiColaborador = new ApiColaboradorService;

export default {
    namespaced: true,
    state: {
        filtropaciente: {
            dni: "",
        },

        nofound: false,

        paciente: {
            dni: "",
            nombre_completo: "",
            cargo_carrera: "",
            admision: "",
            edad: "",
            sexo: "",
            tipo: "estudiante",
            tipo_sangre: "",
            contacto_emergencia: "",
            nombre_contacto:"",
            hta_fam: "",
            dm_fam:"",
            neoplasias_fam:"",
            otros_fam:"",
            enfermedades_pers:"",
            alergias_pers:"",
            cirugias_pers:"",
            peso:"",
            talla:"",
            neoplasias_pers:"",
            fracturas_pers:"",
            hospitalizaciones_pers:"",
            otros_pers:"",
            observaciones:""
        },

        pacienteDefault: {
            dni: "",
            nombre_completo: "",
            cargo_carrera: "",
            admision: "",
            edad: "",
            sexo: "",
            tipo: "estudiante",
            tipo_sangre: "",
            contacto_emergencia: "",
            nombre_contacto:"",
            hta_fam: "",
            dm_fam:"",
            neoplasias_fam:"",
            otros_fam:"",
            enfermedades_pers:"",
            alergias_pers:"",
            cirugias_pers:"",
            peso:"",
            talla:"",
            neoplasias_pers:"",
            fracturas_pers:"",
            hospitalizaciones_pers:"",
            otros_pers:"",
            observaciones:""
        },

        listpacientes: [],

        loading: false,
        loadingFindDNI:false,
        loadingadd:false,
        editPaciente:false,
        loadingGenaratePDF: false
    },
    mutations: {


        setPaciente(state, payload)
        {
            state.paciente = Object.assign({}, payload)
        },

        setPacienteObj(state, payload)
        {
            state.paciente = Object.assign({}, state.pacienteDefault)
            state.paciente = Object.assign({}, payload)
        },


        setDataStudent(state, data){
            state.paciente.nombre_completo = data.result.nomalu;
            state.paciente.cargo_carrera = data.result.specialtyName;
            var yeradmin = data.result.period.substr(0, 4);
            var periodAdmin = data.result.period.substr(
              4,
              data.result.period.length - 1
            );

            state.paciente.admision = yeradmin + "-" + periodAdmin;
        },

        setDataColaborador(state, data){
            state.paciente.nombre_completo = data.nombrecompleto
            state.paciente.cargo_carrera = data.cargo
        },

        resetPaciente(state){
            state.paciente = Object.assign({}, state.pacienteDefault)
        },

        openNuevoPaciente(state)
        {
            state.paciente = Object.assign({}, state.pacienteDefault)
            state.editPaciente = true
            
        },

        switchEditpaciente(state, open)
        {
            state.editPaciente = open
        }
    },
    actions: {
        async findPaciente({ commit, state }) {
            if(state.filtropaciente.dni.trim() == "") return
            state.loading = true
            try {
                const data = await PacienteSV.findPaciente(state.filtropaciente)
                console.log(data)
                commit('setPaciente', data)
                
                state.loading = false
                state.nofound = false
                state.editPaciente = false
            } catch (error) {
                console.log(error)
                state.loading = false
                state.nofound = true
                state.paciente = Object.assign({}, state.pacienteDefault)
                state.paciente.dni = state.filtropaciente.dni
            }
        },

        async getApiData({commit, state})
        {

            state.loadingFindDNI = true

            if(state.paciente.tipo == 'estudiante')
            {
                try{
                    const data = await ApiStudent.get(state.paciente.dni)
                    commit('setDataStudent', data)
                }
                catch(error)
                {
                    console.log(error)
                }
            }

            if(state.paciente.tipo == 'colaborador')
            {
                try{
                    const data = await ApiColaborador.get(state.paciente.dni)
                    commit('setDataColaborador', data)
                }
                catch(error){
                    console.log(error)
                }
            }

            state.loadingFindDNI = false


        },


        async addPaciente({commit, state})
        {
            state.loadingadd = true
            try{
                const data = await PacienteSV.addPaciente(state.paciente)
                store.commit('silderDerechaComponent/closeSilder')
                commit('setPacienteObj', data)
                store.dispatch('topico/Atenciones/getListPacientesAtenciones')
                store.commit('topico/Atenciones/selectPaciente', data) 
                store.dispatch('topico/Atenciones/getListAtencionesPorPaciente')
                state.loadingadd = false
                state.editPaciente = false
                state.nofound = false
                swal({
                    title: "Se guardo con exito",
                    icon: "success"
                })
            }
            catch(error){
                console.log(error)
                state.loadingadd = false
                swal({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
            }
        },



        //REPORTE PDF

        //GENERAR REPORTES

        async generatePDFReporteFichaPaciente({commit, state}, paciente){
            state.loadingGenaratePDF=true
            try{
                //state.filteratenciones.sede = store.state.user.roles.sedes
                const data = await PacienteSV.generatePDFReporteFichaPaciente(paciente)
                state.loadingGenaratePDF=false
            }
            catch(error){
                state.loadingGenaratePDF=false
                console.log(error)
            }
        }, 


    }
}