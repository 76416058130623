import swal from 'sweetalert';
import ArchivosAdjuntosService from '../services/ArchivosAdjuntosService.js'
const archivoAdj = new ArchivosAdjuntosService
export default {
  namespaced: true,
  state: {
    archivoAdjunto: {
      tipo_documento: "",
      nombre_documento: "",
      file: "",
      registro_id: ""
    },

    archivoAdjuntoDefault: {
      tipo_documento: "",
      nombre_documento: "",
      file: "",
      registro_id: ""
    },

    listaArchivoAdjuntos:[],

    loadingListaAD: false,

    loadingSaveFiles: false
  },
  mutations: {
    changeAdjunto(state, event) {
        if (
          event != null &&
          !(
            event.type == "image/jpeg" ||
            event.type == "image/png" ||
            event.type == "image/jpg" ||
            event.type == "application/pdf" ||
            event.type ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            event.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        ) {
          swal({
            title: "ARCHIVO NO PERMITIDO",
            text: "Porfavor solo agregre archivos: png, jpg, pdf, word, excel",
            icon: "error",
          });
  
          state.archivoAdjunto.file = null;
          return;
        }
        if (event != null) {
          state.archivoAdjunto.file = event;
        }
      },

      asignarRegistroID(state, registro_id)
      {
        state.archivoAdjunto.registro_id = registro_id
      },

      resetArchivoAdjunto(state)
      {
        state.archivoAdjunto = Object.assign({}, state.archivoAdjuntoDefault)
      },

      resetListaArchivosAdjuntos(state)
      {
        state.listaArchivoAdjuntos = []
      }
  },
  actions: {
    async getListArchivosAdjunto({state, commit, dispatch}, registro_id)
    {
        state.loadingListaAD =true
        state.listaArchivoAdjuntos = []
        try
        {
            const data = await archivoAdj.get({registro_id:registro_id})
            state.listaArchivoAdjuntos = data
            state.loadingListaAD =false
            console.log(data)
        }
        catch(error)
        {
            console.log(error)
            state.loadingListaAD =false

        }
    },
    async addFileAdjunto({state, commit, dispatch})
    {
        state.loadingSaveFiles = true
        try
        {
            let formdata = new FormData()

            for(let key in state.archivoAdjunto)
            {
                formdata.append(key, state.archivoAdjunto[key])
            }
            const data = await archivoAdj.add(formdata)
            dispatch('getListArchivosAdjunto', state.archivoAdjunto.registro_id)
            commit('resetArchivoAdjunto')
            state.loadingSaveFiles = false
        }
        catch(error)
        {
            console.log(error)
            state.loadingSaveFiles = false

        }
    },
    async deleteFileAdjunto({state, commit, dispatch}, idarchivo)
    { 
        const confirmar = await swal({
            title :"¿Desea eliminar este documento?",
            icon: "warning",
            dangerMode: true,
            buttons:['CANCELAR', 'CONFIRMAR']
        })
        if(!confirmar){ return }
        try
        {
            const data = await archivoAdj.delete(idarchivo)
            dispatch('getListArchivosAdjunto', state.archivoAdjunto.registro_id)
            swal({
                title: "Se eliminó el documento",
                icon: "success"
            })
        }
        catch(error)
        {
            console.log(error)

        }
    }
  },
};
