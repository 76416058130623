import HorarioComedorService from "../services/HorarioComedorService";
import VerificarIngresoComedorService from "../services/VerificarIngresoComedorService"
import errorSound from '@/assets/Error.mp3';
import correctSound from '@/assets/Correct.mp3';


const HorarioComedorSV = new HorarioComedorService();
const verificacionService = new VerificarIngresoComedorService
export default{
    namespaced: true,
    state:{
        filters:{
          sede: "",
          tipo_comida: "",
          dni: "",
          nombre_completo: "",
          admision: "",
          carrera: "",
          fecha: "",
          bloque_name: ""
        },
        verificacion:{
            codepass: "",
            tipo_comida: "",
            dni_sincode:"",
            sede: ""
        },
        verificacionDefault:{
            codepass: "",
            tipo_comida: "",
            dni_sincode:"",
            sede: ""
        },
        validacionIngreso:{
            estado: null,
            error_overlay: false,
            message_error: null,
    
        },
        validacionIngresoDefault:{
            estado: null,
            error_overlay: false,
            message_error: null
        },

        listaIngresos:[],

        tipoComidaIngreso: {
            tipo_comida: "",
            bloque_name: "",
            mensaje_error: "",
          },
          tipoComidaIngresoDefault: {
            tipo_comida: "",
            bloque_name: "",
            mensaje_error: "",
          },
          sedeSeleccionada: "",
        loadingVerificacion: false,
        loadingTipoComidaIngreso: false,
        loadingListIngresos: false
    },

    mutations:{
        setListaIngresos(state, payload)
        {
            state.listaIngresos = payload
        },

        resetVerificacion(state)
        {
            state.verificacion = Object.assign({}, state.verificacionDefault)
        },

        setValidation(state, payload) {
          state.validacionIngreso = Object.assign({}, payload);
          if (payload.estado === 'ERROR') {
              const audio = new Audio(errorSound);
              audio.play().catch(error => {
                  console.error('Error al reproducir el audio de error:', error);
              });
          } else if (payload.estado === 'CORRECTO') {
              const audio = new Audio(correctSound);
              audio.play().catch(error => {
                  console.error('Error al reproducir el audio correcto:', error);
              });
          }
      },

        resetValidation(state, payload)
        {
            state.validacionIngreso = Object.assign({}, state.validacionIngresoDefault)
        
        },
        setTipoComidaIngreso(state, payload) {
            state.tipoComidaIngreso = Object.assign({},state.tipoComidaIngresoDefault);
            state.tipoComidaIngreso = Object.assign({}, payload);
          },
      
          setTipoComidaIngresoError(state, error) {
            state.tipoComidaIngreso = Object.assign(
              {},
              state.tipoComidaIngresoDefault
            );
            state.tipoComidaIngreso.mensaje_error = error;
          },

          seleccionarSEDEvalidacionComedor(state, sede) {
            localStorage.setItem("sedeSeleccionadaComedor", sede);
          },
      
          getSedeSeleccionada(state) {
            const sedeSeleccionadaLS = localStorage.getItem(
              "sedeSeleccionadaComedor"
            );
      
            if (sedeSeleccionadaLS) {
              state.sedeSeleccionada = sedeSeleccionadaLS;
            }
          },

    },


    actions:{

        async ObtenerComidaYbloque({ state, commit }, sede) {
            state.loadingTipoComidaIngreso = true;
            try {
              const data = await HorarioComedorSV.ObtenerComidaYbloque({
                sede: state.sedeSeleccionada,
              });
              console.log(data)
              commit("setTipoComidaIngreso", data);
              state.loadingTipoComidaIngreso = false;
            } catch (error) {
              console.log(error);
              state.loadingTipoComidaIngreso = false;
              commit("setTipoComidaIngresoError", error.response.data.message);
            }
          },

        async verificacionIngresoComedor({state, commit, dispatch})
        {
            state.loadingVerificacion = true
            commit('resetValidation')
            try
            {
                if(state.tipoComidaIngreso.tipo_comida != ""){
                  state.verificacion.tipo_comida = state.tipoComidaIngreso.tipo_comida
                }

                state.verificacion.sede = state.sedeSeleccionada

                const data = await verificacionService.scanQR(state.verificacion)
                commit('setValidation', { estado: "CORRECTO", error_overlay: false, message_error: null})
                state.loadingVerificacion = false

                commit('resetVerificacion')

                dispatch('getListaIngresos')

            } 
            catch(error)
            {
                console.log(error)
                state.loadingVerificacion = false

                commit('setValidation', { estado: "ERROR", error_overlay: true, message_error: error.response.data.message})
                commit('resetVerificacion')
            }
        },


        async getListaIngresos({state, commit}){
          state.loadingListIngresos = true
          try
          {
            console.log(state.filters)
            const data  = await verificacionService.getListaIngresos(state.filters)
            commit('setListaIngresos', data)
            state.loadingListIngresos = false
          }
          catch(error)
          {
            console.log(error)
            state.loadingListIngresos = false
          }
        },


        async getReporteExcel({state, commit, dispatch})
        {
            swal({
                title: "Cargando...",
                text: "Por favor, espere un momento.",
                icon: "info",
                button: false, 
                closeOnClickOutside: false, 
                closeOnEsc: false,
                allowOutsideClick: false 
              });
            try
            {
                const data = await verificacionService.getReporteExcel(state.filters)
                swal({
                    title: "Reporte generado correctamente",
                    icon: "success",
                  });
            }
            catch(error)
            {
                console.log(error)
                swal({
                    title: "Ha ocurrido un error, vuelve a intentar",
                    icon: "error",
                  });

            }
        }


    }
}